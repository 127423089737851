<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
import webConfig from "@/config/web.config/index";
import { setmMxpanelUserInfo, setmMxpanelValue } from "@/utils/mixpanel"
// import { StatsigClient } from '@statsig/js-client'
// import { runStatsigSessionReplay } from '@statsig/session-replay';
// import { runStatsigAutoCapture } from '@statsig/web-analytics';

export default {
  name: "App",
  mounted() {
    // const mixpanelDeviceId = window.sessionStorage.getItem("mixpanelDeviceId");
    // //录屏
    // const myStatsigClient = new StatsigClient(
    //   webConfig.statsigId,
    //   { userID: mixpanelDeviceId }
    // );
    // runStatsigSessionReplay(myStatsigClient);
    // runStatsigAutoCapture(myStatsigClient);
    // await myStatsigClient.initializeAsync();
    const urlParams = this.$utils.getUrlParams(window.location.href);
    window.sessionStorage.setItem('urlQuery', JSON.stringify(urlParams));
    // 设置mixpanel用户属性
    let userInfo = {
      user_source: "web",
      user_type: "new",
      web_version: webConfig.webVersion
    }
    // 需要检查的参数列表
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];
    // 动态添加存在的 UTM 参数
    utmParams.forEach(param => {
      if (urlParams[param]) {
        userInfo[param] = urlParams[param];
      }
    });
    setmMxpanelUserInfo(
      userInfo
    )
    document.body.addEventListener('touchstart', function () { }); //移动端:active伪类无效的解决方法
    // 阻止双击放大页面
    document.addEventListener('dblclick', function (event) {
      event.preventDefault();
    }, { passive: false });
  },

  methods: {
    beforeunload() {
      window.sessionStorage.removeItem("onBoardingRecordInfo");
      window.sessionStorage.removeItem("paywallCountDownTime")
      window.sessionStorage.removeItem("paywallDiscountCountDownTime")
      this.$store.commit("resetOnBoardingRecordInfo")
    }
  }

};
</script>
